<template>
  <div>
    <p class="mt-5 mb-0 f-w-500 text-capitalize">
      {{ $t("c.outlets.opening_hours") }}
    </p>
    <p class="text-muted mb-4">
      {{ $t("c.outlets.set_hours_your_outlet_open_x", [orderingService.slug]) }}
    </p>
    <div class="row d-none d-lg-flex">
      <div class="col-auto" style="width: 249px">
        <p class="f-w-500 mb-1">{{ $t("label.day") }}</p>
      </div>
      <div class="col-auto px-2" style="width: 120px">
        <p class="f-w-500 mb-1">{{ $t("label.from") }}</p>
      </div>
      <div class="col px-2">
        <p class="f-w-500 mb-1">{{ $t("label.to") }}</p>
      </div>
    </div>
    <Weekday
      v-for="weekday in weekdays"
      :key="weekday.day"
      :weekday="weekday.day"
      :outlet-id="outletId"
      :ordering-service="orderingService.orderingServiceEnum"
      :opening-hours="weekday.slots"
    />
  </div>
</template>

<script>
import Weekday from "./Weekday.vue";
import weekdays from "@/utils/enums/weekdays";
import { groupBy, prop, map, propOr } from "ramda";

export default {
  name: "OrderingService",
  components: {
    Weekday,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    orderingService: {
      type: Object,
      required: true,
    },
  },
  computed: {
    weekdays() {
      const weekday = groupBy(
        prop("dayOfWeek"),
        this.orderingService.openingHours
      );
      return map(
        (day) => ({
          day,
          slots: propOr([], day, weekday),
        }),
        weekdays
      );
    },
  },
};
</script>
